import { Context } from "./app";
import { post, get, CommonReponse } from "./data";

export function getMe(ctx: Context): Promise<CommonReponse<any>> {
    return get<any>(ctx, '/app/api/me');
}

export interface Session {
    token: string;
}

export interface CapabilityTokenClaims {
    access_key: string
    organization_key: string
    user_email?: string
    user_first_name?: string;
    user_key?: string
    user_last_name?: string
    exp: number
    iat: number
}

export interface SessionTokenClaims {
    volie_connect_session_id: number
    exp: number
    iat: number
}

function parseJwt<T>(token: string): T {
    if (!token) { return null }
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload) as T;
};

export function capabilityToken(context: Context): CapabilityTokenClaims {
    return parseJwt<CapabilityTokenClaims>(context.state.authToken);
}

export function sessionToken(context: Context): SessionTokenClaims {
    if (context.session) {
        return parseJwt<SessionTokenClaims>(context.session.token)
    }
    return null;
}

export function createSession(ctx: Context): Promise<CommonReponse<Session>> {
    return post<Session>(ctx, '/connect/session', { token: ctx.state.authToken });
}

export function getSession(ctx: Context): Promise<CommonReponse<SessionTokenClaims>> {
    return get<SessionTokenClaims>(ctx, '/connect/session')
}
