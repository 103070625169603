import { Context, refreshToken } from "./app";

/**
 * @ignore
 */
export const cacheNamespace = 'volie.connect';

/**
 * @ignore
 */
export const volieApi = process.env.VUE_APP_CONNECT_VOLIE_API;

/**
 * @ignore
 */
export const volieWebsocket = process.env.VUE_APP_CONNECT_REALTIME_URL || '';

export interface CommonReponse<T> {
    Data: T;
    Error?: string;
}

export function copy<T>(obj: T): T {
    return JSON.parse(JSON.stringify(obj));
}

export function storeGet<T>(k: string): T {
    k = `${cacheNamespace}:${k}`;
    return JSON.parse(window.localStorage.getItem(k) || '');
}

export function storeSet(k: string, v: any) {
    k = `${cacheNamespace}:${k}`;
    return window.localStorage.setItem(k, JSON.stringify(v));
}

export interface XhrClientOptions {
    baseUrl?: string | null;
    headers?: any;
}

export class XhrClient {
    baseUrl: string;
    headers: any;

    constructor(options: XhrClientOptions) {
        this.baseUrl = options.baseUrl || '';
        this.headers = options.headers;
    }

    put<T>(ctx: Context | null, path: string, payload?: any, headers = {}): Promise<CommonReponse<T>> {
        return this.xhr<T>(ctx, 'PUT', path, payload, headers);
    }

    post<T>(ctx: Context | null, path: string, payload?: any, headers = {}): Promise<CommonReponse<T>> {
        return this.xhr<T>(ctx, 'POST', path, payload, headers);
    }

    get<T>(ctx: Context | null, path: string, payload?: any, headers = {}): Promise<CommonReponse<T>> {
        return this.xhr<T>(ctx, 'GET', path, payload, headers);
    }

    destroy<T>(ctx: Context | null, path: string, payload?: any, headers = {}): Promise<CommonReponse<T>> {
        return this.xhr<T>(ctx, 'DELETE', path, payload, headers);
    }

    async xhr<T>(ctx: Context | null, method: string, path: string, payload?: any, headers = {}): Promise<CommonReponse<T>> {
        return new Promise<CommonReponse<T>>((resolve, reject) => {

            let retry = 1;
            const exec = () => {

                method = method.toUpperCase();

                const opts: any = {
                    method,
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        ...this.headers,
                        ...headers,
                    },
                    credentials: 'include',
                };

                let token = ctx?.session?.token
                if (token) {
                    opts.headers.Authorization = `Bearer ${token}`;
                }

                let url = this.baseUrl + path;

                if (payload) {
                    if ('GET' === method) {
                        url += `?${
                            Object.keys(payload)
                                .filter((k) => !(payload[k] === null || payload === undefined))
                                .map((k) => encodeURIComponent(k) + '=' + encodeURIComponent(payload[k]))
                                .join('&')
                        }`;
                    } else {
                        opts.body = JSON.stringify(payload);
                    }
                }

                fetch(url, opts)
                    .then((res: any) => {
                        if (res.ok) {
                            res.json().then((json: any) => resolve(json as CommonReponse<T>));
                        } else {
                            
                            // handle expired tokens
                            try {
                                if (res.status == 401) {
                                    res.json().then(async (j: any) => {
                                        if (!ctx || retry <= 0) {
                                            reject(j)
                                            return
                                        }
                                        //  refresh capability token
                                        if (j.Error == "Expired capability token" || j.Error == "Expired connect session") {
                                            retry--;
                                            await refreshToken(ctx);
                                            exec();
                                        } else {
                                            reject(j);
                                        }
                                    });
                                }
                            } catch (err) {
                                throw err;
                            }
                        }
                    })
                    .catch((err: any) => {
                        reject(new Error(err.Error));
                    });
            };

            exec();
        });

    }
}

export const apiClient = new XhrClient({ baseUrl: volieApi });

export function put<T>(ctx: Context | null, path: string, payload?: any, headers = {}): Promise<CommonReponse<T>> {
    return apiClient.xhr<T>(ctx, 'PUT', path, payload, headers);
}

export function post<T>(ctx: Context | null, path: string, payload?: any, headers = {}): Promise<CommonReponse<T>> {
    return apiClient.xhr<T>(ctx, 'POST', path, payload, headers);
}

export function get<T>(ctx: Context | null, path: string, payload?: any, headers = {}): Promise<CommonReponse<T>> {
    return apiClient.xhr<T>(ctx, 'GET', path, payload, headers);
}

export function destroy<T>(ctx: Context | null, path: string, payload?: any, headers = {}): Promise<CommonReponse<T>> {
    return apiClient.xhr<T>(ctx, 'DELETE', path, payload, headers);
}
