import { EventBus } from "./events/event_bus";
import { capabilityToken, createSession, Session } from "./auth"
import { WebsocketClient } from "./realtime/websocket_client";
import { volieWebsocket } from "./data";

// // Application context
export interface Context {
    id: any // identifies context
    config: Config  // application configuration
    session: Session | null // authorization token
    bus: EventBus // application event stream
    websocket: WebsocketClient // realtime websocket connection
    state: State // holds app state
}

export interface State {
    authToken?: string // auth token
    authTokenRenewHandle?: any; // holds handle from setTimeout
}

// Application configuration
export interface Config {
    disableWebsocketConnect?: boolean // disables websocket auto connect
    disablePremptiveSessionRenewal?: boolean
    tokenHandler: TokenHandler // gets called when token has expired
}

export async function refreshToken(context: Context): Promise<any> {
    return new Promise(async (resolve, reject) => {
        context.config.tokenHandler(null, async (newToken) => {
            context.state.authToken = newToken

            if (!context.config.disablePremptiveSessionRenewal) {
                // scheudle early token refresh
                if (newToken) {
                    const ct = capabilityToken(context);
                    if (ct) {
                        const secondsRemaining = ct.exp - ((new Date().getTime()) / 1000); // in seconds
                        const timeout = (secondsRemaining - 60) * 1000;
                        context.state.authTokenRenewHandle = setTimeout(() => {
                            refreshToken(context);
                        }, timeout);
                    }
                }
            }

            try {
                await refreshSession(context)
                resolve(null);
            } catch (e) {
                throw e;
            }
        });
    })
}

export async function refreshSession(context: Context): Promise<any> {
    return new Promise(async (resolve, reject) => {
        var resp = await createSession(context);
        if (!resp.Data || !resp.Data.token) {
            context.session = null;
            reject();
            return;
        }
        context.session = {
            token: resp.Data.token,
        }
        context.websocket.updateCredentials(resp.Data.token);
        resolve(null);
    })
}

export type TokenHandler = (oldToken: string, accept: (newToken: string) => void) => void;

export class App implements Context {
    id: number;
    config: Config  // application configuration
    session: Session | null // authorization token
    bus: EventBus // application event stream
    websocket: WebsocketClient // realtime websocket connection
    state: State;

    constructor(config: Config) {
        this.id = new Date().getTime();
        this.config = config;
        this.bus = new EventBus();
        this.websocket = new WebsocketClient({
            Url: volieWebsocket,
        });
        this.session = null;
        this.state = {};
    }

    async startSession(): Promise<any> {
        return new Promise(async (resolve) => {
            try {
                await refreshToken(this);
                this.websocket.open(this.session.token);
                resolve(null);
            } catch {
                throw new Error("Could not start session")
            }
        })
    }
}
