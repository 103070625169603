import { capabilityToken, createSession, getSession, sessionToken } from "./auth";
import { App, refreshSession, refreshToken } from "./app";
import { initialize } from "./index";

export const Core = {
    App: App,
    capabilityToken: capabilityToken,
    createSession: createSession,
    getSession: getSession,
    initialize: initialize,
    refreshSession: refreshSession,
    refreshToken: refreshToken,
    sessionToken: sessionToken,
}

const w = window as any;
w.Volie = w.Volie || {} as any;
w.Volie.Connect = w.Volie.Connect || {}
w.Volie.Connect.Core = Core;
