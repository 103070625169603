import { Config, App } from "./app";

export * from "./app";
export * from "./auth";
export * from "./data";
export * from "./events";
export * from "./events/event_bus";
export * from "./index";
export * from "./realtime/websocket_bus";
export * from "./realtime/websocket_client";
export * from "./service";

export function initialize(config: Config): App {
    const app = new App(config);
    app.startSession();
    return app;
}
