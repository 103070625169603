import { ChannelIdentity } from "./websocket_client";

export interface SubscriptionKey {
    type: string;
    channel?: ChannelIdentity;
    tag?: string;
}

function SubscriptionKeyString(key: SubscriptionKey): string {
    const channel = key.channel || { Type: null, ID: null };
    return [key.type, channel.Type, channel.ID, key.tag].filter((x) => x).join('.');
}

function SubscriptionKeys(key: SubscriptionKey): SubscriptionKey[] {
    const keys: SubscriptionKey[] = [];
    if (key.tag) {
        keys.push({
            channel: key.channel,
            type: key.type,
        })
    }
    if (key.channel) {
        keys.push({
            channel: key.channel,
            type: key.type,
        })
        keys.push({
            channel: { Type: key.channel.Type, ID: null },
            type: key.type,
        })
    }
    keys.push({
        type: key.type,
    })
    return keys;
}

export class WebsocketEventBus {
    private subscribers: any;

    constructor() {
        this.subscribers = {};
    }

    public unsubscribe(key: SubscriptionKey, subscriber: (response: any) => void) {
        const keyStr = SubscriptionKeyString(key);
        if (keyStr) {
            const handlers = this.subscribers[keyStr] as any;
            if (handlers) {
                for(let i = handlers.length - 1; i >= 0; i--) {
                    if (handlers[i] === subscriber) {
                        handlers.splice(i, 1);
                    }
                }
            }
        }
    }

    public subscribe(key: SubscriptionKey, handler: (...args: any[]) => void) {
        const keyStr = SubscriptionKeyString(key);
        if (keyStr) {
            let handlers = this.subscribers[keyStr] as any;
            if (!handlers) {
                handlers = [];
            }
            handlers.push(handler);
            this.subscribers[keyStr] = handlers;
        }
    }

    public send(key: SubscriptionKey, ...args: any[]): void {
        SubscriptionKeys(key).forEach((k) => {
            const handlers = this.subscribers[SubscriptionKeyString(k)] as any;
            if (handlers) {
                handlers.forEach((x: any) => x.apply(this, args));
            }
        });
    }
}
