import { Context } from "../app";
import { get, CommonReponse } from "../data";

export interface TimeResult {
    Time: Date
}

export function getTime(ctx: Context): Promise<CommonReponse<TimeResult>> {
    return get(ctx, "/connect/v1/time");
}
